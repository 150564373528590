import { HTMLMotionProps, motion, Variants } from 'framer-motion'

interface Props extends HTMLMotionProps<'div'> {
  text: string
  delay?: number
  duration?: number
  whileInViewOnly?: boolean
}

interface ChildProps extends HTMLMotionProps<'div'> {
  children: React.ReactNode
  container: Variants
}

const AsyncWavyText = ({ children, container, ...props }: ChildProps) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false, amount: 1 }}
      variants={container}
      {...props}
    >
      {children}
    </motion.div>
  )
}

const SyncWavyText = ({ children, container, ...props }: ChildProps) => {
  return (
    <motion.div variants={container} initial="hidden" animate="visible" {...props}>
      {children}
    </motion.div>
  )
}

const WavyText = ({ text, delay = 0, duration = 0.05, whileInViewOnly, ...props }: Props) => {
  const letters = Array.from(text)
  const container: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: duration, delayChildren: i * delay },
    }),
  }

  const child: Variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 200,
      },
    },
    hidden: {
      opacity: 0,
      y: 20,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 200,
      },
    },
  }

  const Component = whileInViewOnly ? AsyncWavyText : SyncWavyText

  return (
    <Component container={container}>
      <div className="flex justify-center md:justify-start">
        {letters.map((letter, index) => (
          <motion.span key={index} variants={child}>
            {letter === ' ' ? '\u00A0' : letter}
          </motion.span>
        ))}
      </div>
    </Component>
  )
}

export default WavyText
