import Alert from 'components/alert'
import { useState } from 'react'
import { SendEmail } from 'requests/send-email'

const Suggestions = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(true)

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setShowMessage(true)
    await SendEmail(message, name, email)
  }

  const isValid = (updatedMessage: string, updatedName: string) => {
    if (updatedMessage && updatedName) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }

  const handleMessage = (updatedMessage: string) => {
    isValid(updatedMessage, name)
    setMessage(updatedMessage)
  }

  const handleName = (updatedName: string) => {
    isValid(message, updatedName)
    setName(updatedName)
  }

  return (
    <div className="flex flex-col items-center justify-center mt-12 md:mt-24 bg-red-100 rounded-xl">
      <div className="w-full px-3 md:px-12">
        {showMessage && <Alert message="Thank you for sending your suggestion!" />}
      </div>
      <div className="w-full text-center py-5 text-xl">
        Please share any wishes or advice or date night ideas with us! ❤️
      </div>
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-3 w-full p-3 md:px-20 rounded-md md:rounded-2xl"
        data-static-form-name="suggestions"
      >
        <div className="flex flex-col md:flex-row gap-3 w-full">
          <input
            className="input input-bordered w-full"
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => handleName(e.currentTarget.value)}
          />
          <input
            className="input input-bordered w-full"
            type="text"
            name="email"
            placeholder="Email (optional)"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>
        <textarea
          className="textarea textarea-bordered h-48"
          name="message"
          placeholder="Message"
          value={message}
          onChange={(e) => handleMessage(e.currentTarget.value)}
        ></textarea>
        <div className="flex justify-center mt-3 md:mt-5">
          <input
            className="btn btn-secondary btn-md rounded-lg w-48"
            type="submit"
            value="Send Message"
            disabled={disable || showMessage}
          />
        </div>
      </form>
    </div>
  )
}

export default Suggestions
