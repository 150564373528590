import Wedding1 from 'assets/w1.png'

const CoupleIcon = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center md:mt-6 md:mb-6">
      <div className="flex items-center flex-col justify-center bg-frame_bg md:bg-none object-cover w-72 h-72 md:w-96 md:h-96 bg-cover bg-center md:mr-1 mt-3 mb-3 ">
        <svg className="heart fill-red-500 w-12 h-12 ml-3 mt-4 md:mt-8">
          <path
            d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
          />
        </svg>
        <img alt="wedding1" className="block object-cover object-center rounded-lg mr-3 md:mr-0" src={Wedding1} />
      </div>
    </div>
  )
}

export default CoupleIcon
