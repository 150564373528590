import DrinkImage from 'assets/d1.png'
import FrameImage from 'assets/frame2.png'
import WavyText from 'components/wavy-text'
import { motion, useAnimation } from 'framer-motion'

interface DrinkType {
  name: string
  ingredients?: string
}

const DRINKS: DrinkType[] = [
  {
    name: 'Whisky Sour',
    ingredients: 'whisky and sour mix',
  },
  {
    name: 'Long island Iced Tea',
    ingredients: 'vodka, rum, gin, tequila, triple sec, sour mix, coke',
  },
  {
    name: 'Cosmo',
    ingredients: 'vodka, cranberry juice, triple sec, lemon',
  },
  {
    name: 'Tequila Sunrise',
    ingredients: 'tequila, orange juice, grenadine',
  },
  {
    name: 'Screwdriver',
    ingredients: 'vodka, orange juice',
  },
  {
    name: 'Malibu Sunset',
    ingredients: 'Pineapple juice, OJ, coconut rum, and grenadine',
  },
  {
    name: 'Pineapple Vodka',
    ingredients: 'vodka, pineapple juice, lime, layer of grenadine',
  },
  {
    name: 'Bay Breeze',
    ingredients: 'vodka, pineapple juice, and cranberry juice, simple syrup',
  },
]

const BEERS: DrinkType[] = [
  {
    name: 'Corona, Heineken, TsingTao',
  },
]

const WINES: DrinkType[] = [
  {
    name: 'Red & White',
  },
]

const NON_ALCOHOLICS: DrinkType[] = [
  {
    name: 'Juice',
    ingredients: 'pineapple, orange, cranberry',
  },
  {
    name: 'Soda',
    ingredients: 'ginger ale, coke cola, sprite, club soda, and tonic water',
  },
  {
    name: 'Shirley Temple',
    ingredients: 'ginger ale, grenadine',
  },
]

interface SectionProps {
  title: string
  items: DrinkType[]
}

const Section = ({ title, items }: SectionProps) => {
  return (
    <div className="flex justify-center text-center flex-col mb-6">
      <span className="text-3xl md:text-5xl font-bold md:font-extrabold tracking-wider mb-4 font-alex_brush">
        <div className="flex justify-center">
          <WavyText text={title} duration={0.1} />
        </div>
      </span>
      {items.map(({ name, ingredients }, idx) => (
        <div key={idx} className="flex flex-col justify-center mb-3">
          <span className="text-lg md:text-2xl font-extrabold tracking-wider">{name}</span>
          {ingredients && (
            <span className="mx-auto md:text-lg w-3/4 md:w-full font-extralight md:font-light italic tracking-wider text-secondary-focus">
              {ingredients}
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

const Drinks = () => {
  const animation = useAnimation()
  const drinkSequence = async () => {
    await animation.start({ y: [-45, 0] })
    animation.start({ rotate: [5, -5, 0] })
  }
  return (
    <div className=" md:container md:mx-auto mt-8 mb-8">
      <div className="flex justify-center flex-col">
        <div className="flex justify-center items-center mb-12">
          <div className="block object-cover object-center w-1/4 h-1/3 md:w-1/6 md:h-1/6 rounded-lg mr-6 rotate-180">
            <motion.img animate={{ opacity: 1, y: 0, scale: 1, x: [100, 0] }} alt="frame-image" src={FrameImage} />
          </div>
          <div className="block object-cover object-center w-1/3 h-1/3 md:w-1/6 md:h-1/6 rounded-lg">
            <motion.img
              animate={animation}
              onLoad={drinkSequence}
              transition={{ type: 'spring', bounce: 0.25, duration: 0.5 }}
              alt="drink-image"
              src={DrinkImage}
            />
          </div>
          <div className="block object-cover object-center w-1/4 h-1/3 md:w-1/6 md:h-1/6 rounded-lg ml-6 rotate-180 -scale-x-100">
            <motion.img animate={{ opacity: 1, y: 0, scale: 1, x: [100, 0] }} alt="frame-image" src={FrameImage} />
          </div>
        </div>
        <div className="mb-8 text-center tracking-wider">
          <div className="text-4xl md:text-7xl font-bold md:font-extrabold font-alex_brush">
            <div className="flex justify-center">
              <WavyText text="Drinks" duration={0.1} />
            </div>
          </div>
          <div className="text-xl md:text-2xl">---Open Bar---</div>
        </div>
        <Section title="Beers" items={BEERS} />
        <Section title="Wines" items={WINES} />
        <Section title="Cocktails" items={DRINKS} />
        <Section title="Non-alcoholic" items={NON_ALCOHOLICS} />
        <div className="text-sm md:text-xl font-bold md:font-extrabold text-center tracking-wider">
          <span>And More...</span>
        </div>
        <div className="flex justify-center -mt-20 md:-mt-32">
          <div className="block object-cover object-center w-1/4 h-1/3 md:w-1/6 md:h-1/6 rounded-lg mr-24 md:mr-40 rotate-90">
            <motion.img alt="frame-image" animate={{ opacity: 1, scale: 1, y: [100, 0] }} src={FrameImage} />
          </div>
          <div className="block object-cover object-center w-1/4 h-1/3 md:w-1/6 md:h-1/6 rounded-lg ml-24 md:ml-40">
            <motion.img animate={{ opacity: 1, y: 0, scale: 1, x: [100, 0] }} alt="frame-image" src={FrameImage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Drinks
