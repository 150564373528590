import { motion, useAnimation, Variants } from 'framer-motion'
import useMediaQuery from 'hooks/use-media-query'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

interface Props {
  children: React.ReactNode
  cardVariants?: Variants
}

const TransitionCard = ({ children, cardVariants }: Props) => {
  const matches = useMediaQuery('(min-width: 768px)')
  const [ref, inView] = useInView({ threshold: 1 })
  const animation = useAnimation()

  useEffect(() => {
    if (inView) {
      animation.start('onscreen')
    } else {
      animation.start('offscreen')
    }
  }, [animation, inView])

  const CARD_VARIANTS: Variants = {
    offscreen: {
      opacity: 0,
      y: matches ? -500 : -200,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        bounce: 0.4,
        duration: 0.8,
      },
    },
  }
  return (
    <motion.div ref={ref} initial="offscreen" animate={animation}>
      <motion.div variants={cardVariants || CARD_VARIANTS}>{children}</motion.div>
    </motion.div>
  )
}

export default TransitionCard
