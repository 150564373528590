import Meeting1Gif from 'assets/1-K1stmeet.gif'
import Meeting2Gif from 'assets/1-R1stmeet.gif'
import CloudGif from 'assets/cloud.gif'
import TransitionCard from 'components/transition-card'
import { Variants } from 'framer-motion'
import useMediaQuery from 'hooks/use-media-query'

const LEFT_CARD_VARIANTS: Variants = {
  offscreen: {
    opacity: 0,
    x: -200,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
}

const RIGHT_CARD_VARIANTS: Variants = {
  offscreen: {
    opacity: 0,
    x: 200,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
}

const CENTER_CARD_VARIANTS: Variants = {
  offscreen: {
    opacity: 0,
    y: -200,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
}

const College = () => {
  const matches = useMediaQuery('(min-width: 768px)')
  // console.log('matches', matches)
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="pt-48 md:pt-96 lg:pt-[500px] xl:pt-[600px]">
        <div className="flex justify-center items-center">
          <TransitionCard cardVariants={matches ? LEFT_CARD_VARIANTS : undefined}>
            <img src={Meeting1Gif} alt="meeting 1 gif" className="w-20 h-20 md:w-48 md:h-48" />
          </TransitionCard>
          <TransitionCard cardVariants={matches ? CENTER_CARD_VARIANTS : undefined}>
            <div className="mx-3 md:mx-8">
              <h1 className="text-lg md:text-3xl absolute pl-8 pt-8 md:pl-24 md:pt-32">
                <div className="flex flex-col justify-center">
                  <span className="text-base md:text-xl">April 2012</span>
                  <span>We met at</span>
                  <span>Stony Brook</span>
                  <span>University</span>
                </div>
              </h1>
              <img src={CloudGif} alt="cloud gif" className="w-[176px] md:w-96" />
            </div>
          </TransitionCard>
          <TransitionCard cardVariants={matches ? RIGHT_CARD_VARIANTS : undefined}>
            <img src={Meeting2Gif} alt="meeting 2 gif" className="w-20 h-20 md:w-48 md:h-48" />
          </TransitionCard>
        </div>
      </div>
    </div>
  )
}

export default College
