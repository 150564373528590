import Countdown from 'components/count-down'
import WavyText from 'components/wavy-text'
import CoupleIcon from './coupleIcon'

const Intro = () => {
  return (
    <div className="min-h-screen">
      <div className="flex flex-col md:flex-row h-full">
        <div className="flex justify-center items-end md:items-center md:w-2/3 min-h-[50vh] md:min-h-screen bg-[url('assets/bg1.jpg')] bg-center bg-cover">
          <div className="flex flex-col justify-center text-center md:text-left md:justify-end md:pb-48 md:ml-24 h-full p-12 md:p-0">
            <div>
              <h1 className="font-alex_brush text-4xl lg:text-7xl">
                <WavyText text="Kristyn Shieh" />
              </h1>
              <h1 className="font-alex_brush text-4xl lg:text-7xl">
                <WavyText delay={0.7} text="& Rithy Huot" />
              </h1>
            </div>
            <p className="text-xl md:text-2xl xl:text-4xl">We can't wait to share our special day with you!</p>
          </div>
        </div>
        <div className="md:w-1/3 min-h-[50vh] md:min-h-screen">
          <div className="flex flex-col text-center justify-center mt-12 md:mt-0 h-full">
            <CoupleIcon />
            <h1 className="font-alex_brush text-4xl lg:text-7xl">New Mulan</h1>
            <p className="text-xl md:text-2xl">Saturday August 27th, 2022</p>
            <p className="text-xl md:text-2xl">6:30 In The Evening</p>
            <p className="text-lg md:text-xl">136-17 39th Ave.</p>
            <p className="text-lg md:text-xl">Flushing, NY 11354</p>
            <div className="flex justify-center mt-6">
              <Countdown />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
