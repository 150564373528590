import CoupleInBallonImage from 'assets/w2.png'
import { motion, useAnimation } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

interface Props {
  currentY: number
}

const CoupleInBallon = ({ currentY }: Props) => {
  const [isMoving, setIsMoving] = useState<boolean>(false)
  const [currentDeg, setCurrentDeg] = useState<number>(0)
  const [currentTranslateX, setCurrentTranslateX] = useState<number>(0)
  const ref = useRef<HTMLImageElement>(null)
  const animation = useAnimation()
  const ballonDescendAnimation = async () => {
    animation.start({ rotate: [0, 10, -5], x: 50 })
  }
  const ballonLandingAnimation = async () => {
    await animation.stop()
    await animation.start({ x: [-25, 25] })
  }

  useEffect(() => {
    if (currentY > 0.125 && !isMoving) {
      // start moving
      setIsMoving(true)
      ballonDescendAnimation()
    }
    if (isMoving && currentY < 0.125) {
      // reset to original position
      setIsMoving(false)
      if (ref.current) {
        animation.stop()
        ref.current.style.transform = `rotate(${currentDeg * -1}deg) translateX(${currentTranslateX * -1}px)`
      }
    }
    // if (isMoving && currentY === 1) {
    //   // finish scrolling
    //   setIsMoving(false)
    //   ballonLandingAnimation()
    //   if (ref.current) {
    //     ref.current.style.transform = `rotate(${currentDeg * -1}deg) translateY(${currentTranslateY * -1}px)`
    //   }
    // }
  }, [currentY])

  const onUpdate = (latest: { rotate: number; x: number }) => {
    setCurrentDeg(latest.rotate)
    setCurrentTranslateX(latest.x)
  }

  return (
    <div ref={ref} className="sticky top-10 md:top-0 flex justify-center z-10">
      <div className="block rounded-lg w-48 md:w-96">
        <motion.img
          onUpdate={onUpdate}
          animate={animation}
          transition={{ duration: 1.5, repeat: isMoving ? 1 : Infinity, repeatType: 'reverse' }}
          alt="couple in ballon"
          src={CoupleInBallonImage}
        />
      </div>
    </div>
  )
}

export default CoupleInBallon
