import useRedirect from 'hooks/use-redirect'

const NAVIGATION = [
  { name: 'HOME', link: '/home' },
  { name: 'MENU', link: '/menu' },
  { name: 'DRINKS', link: '/drinks' },
  { name: 'FAQS', link: '/faqs' },
]

const Navbar = () => {
  const { history } = useRedirect()
  const currentNavSelection = history?.location?.pathname?.split('/')[1]

  return (
    <div className="navbar sticky opacity-95 top-0 z-50 bg-white">
      <div className="navbar-start md:hidden">
        <div className="dropdown">
          <label tabIndex={0} className="btn-link text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
            </svg>
          </label>
          <ul tabIndex={0} className="menu dropdown-content mt-3 p-2 shadow rounded-box w-52 bg-white">
            {NAVIGATION.map(({ name, link }) => (
              <li key={name} className="text-secondary">
                <a
                  className={`${
                    currentNavSelection && link.includes(currentNavSelection) && 'btn-active'
                  } btn-link normal-case`}
                  href={link}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="navbar-center md:navbar-start">
        <a href="/home" className="flex-shrink-0 flex items-center sm:justify-self-start cursor-pointer">
          <span className="text-pink-500 font-alex_brush text-xl">K</span>
          <span className="text-gray-900 ml-4 mr-3">
            <svg className="fill-red-500 w-4" viewBox="0 0 32 29.6">
              <path
                d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
              />
            </svg>
          </span>
          <span className="text-pink-500 font-alex_brush text-xl"> R</span>
        </a>
      </div>
      <div className="navbar-end">
        <div className="hidden md:flex items-center">
          <div className="mr-3 lg:mr-8">
            {NAVIGATION.map(({ name, link }) => (
              <a
                href={link}
                key={name}
                className={`${
                  currentNavSelection && link.includes(currentNavSelection) && 'btn-active'
                } btn-link text-secondary mx-3 lg:text-lg`}
              >
                {name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
