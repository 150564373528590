import WavyText from 'components/wavy-text'
import { useViewportScroll } from 'framer-motion'
import { useEffect, useState } from 'react'
import AnswerDateQuestion from './components/answer-date-question'
import AskDateQuestion from './components/ask-date-question'
import College from './components/college'
import CoupleInBallon from './components/coupleInBallon'
import Ending from './components/ending'
import Intro from './components/intro'
import Proposal from './components/proposal'
import Start from './components/start'
import ThankYou from './components/thank-you'
import TheYes from './components/the-yes'
import TyingKnot from './components/tying-knot'
import WeAreDating from './components/we-are-dating'

const Home = () => {
  const [scrollY, setScrollY] = useState<number>(0)
  const { scrollYProgress } = useViewportScroll()
  useEffect(() => {
    scrollYProgress.onChange((y) => {
      setScrollY(y)
    })
  }, [])

  return (
    <div>
      <Intro />
      <div className="bg-[url('assets/pink-clouds.png')] bg-repeat -z-10 bg-contain">
        <CoupleInBallon currentY={scrollY} />
        <div className="min-h-screen">
          <Start />
        </div>
        <div className="min-h-screen">
          <College />
        </div>
        <div className="min-h-screen">
          <AskDateQuestion />
        </div>
        <div className="min-h-screen">
          <AnswerDateQuestion />
        </div>
        <div className="min-h-screen">
          <WeAreDating />
        </div>
        <div className="min-h-screen flex flex-col">
          <Proposal />
          <TheYes />
        </div>
        <div className="min-h-screen">
          <TyingKnot />
        </div>
        <div className="min-h-screen">
          <ThankYou />
        </div>
      </div>
      <div className="flex justify-center py-12 md:py-0">
        {scrollY > 0.9 && (
          <div className="flex flex-col h-[200px] md:h-full">
            <Ending />
            <h1 className="font-alex_brush pt-6 md:pt-3 text-3xl lg:text-9xl">
              <WavyText delay={0.7} text="To Our Next Adventure!" />
            </h1>
          </div>
        )}
      </div>
      <div className="flex justify-center h-[125px] md:h-[250px] bg-[url('assets/ground.png')] bg-repeat z-10 bg-contain"></div>
    </div>
  )
}

export default Home
