export const SendEmail = async (message: string, name: string, email: string) => {
  const response = await fetch('/api/send-email', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ message, name, email }),
  })

  return response.json()
}
