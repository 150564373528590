import { useHistory } from 'react-router-dom'

const useRedirect = () => {
  const history = useHistory()

  const redirect = (path: string) => {
    if (history.location.pathname !== path) history.push(path)
  }

  return { redirect, history }
}

export default useRedirect
