import { HeartIcon } from '@heroicons/react/outline'

const Footer = () => {
  return (
    <footer className="flex justify-center px-6 pt-3 flex-col-reverse items-center md:flex-row lg:text-left">
      <div className="flex items-center justify-center text-center p-4 flex-grow">
        <span>Made with</span>
        <HeartIcon
          className="h-6 w-6 ml-3 mr-2 dark:text-pink-500 fill-current text-pink-300 animate-pulse"
          aria-hidden="true"
        />
        <span>
          <span className="font-alex_brush mr-2">K</span>
          <span className="font-alex_brush mr-1">&</span>
          <span className="font-alex_brush mr-1">R</span>
          {` @ ${new Date().getFullYear()}`}
        </span>
      </div>
    </footer>
  )
}

export default Footer
