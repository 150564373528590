import Layout from 'components/layout'
import Drinks from 'pages/drinks'
import Faqs from 'pages/faqs'
import Home from 'pages/home'
import Menu from 'pages/menu'

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Layout>
          <Route exact path="/home" component={Home} />
          <Route exact path="/menu" component={Menu} />
          <Route exact path="/faqs" component={Faqs} />
          <Route exact path="/drinks" component={Drinks} />
        </Layout>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
