import Router from 'router'

const App = () => {
  return (
    <div className="font-sans">
      <Router />
    </div>
  )
}

export default App
