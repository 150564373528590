import { ArrowCircleDownIcon } from '@heroicons/react/outline'
import CatGif from 'assets/cat.gif'
import CatGif2 from 'assets/cat2.gif'
import CloudGif from 'assets/cloud.gif'
import TransitionCard from 'components/transition-card'
import WavyText from 'components/wavy-text'

const Start = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex justify-between mt-24">
        <ArrowCircleDownIcon className="h-8 w- md:h-12 md:w-12 animate-bounce text-secondary" aria-hidden="true" />
        <div>
          <h1 className="font-alex_brush text-2xl md:text-5xl mx-3">Scroll Down</h1>
        </div>
        <ArrowCircleDownIcon className="h-8 w-8 md:h-12 md:w-12 animate-bounce text-secondary" aria-hidden="true" />
      </div>
      <div className="mt-48 md:mt-96">
        <TransitionCard>
          <div className="flex justify-center items-center">
            <img src={CatGif} alt="cat gif" className="w-20 h-20 md:w-48 md:h-48" />
            <div className="mx-3 md:mx-8">
              <h1 className="font-alex_brush text-2xl md:text-5xl absolute pl-6 pt-16 md:pl-20 md:pt-36">
                <WavyText whileInViewOnly delay={0.2} text="This is how" />
                <WavyText whileInViewOnly delay={0.7} text="the story starts" />
              </h1>
              <img src={CloudGif} alt="cloud gif" className="w-[176px] md:w-96" />
            </div>
            <img src={CatGif2} alt="cat gif 2" className="w-20 h-20 md:w-48 md:h-48" />
          </div>
        </TransitionCard>
      </div>
    </div>
  )
}

export default Start
