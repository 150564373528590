interface Props {
  title: string
  description: string | React.ReactNode
}

const Accordion = ({ title, description }: Props) => {
  return (
    <div tabIndex={0} className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
      <input type="checkbox" className="peer" />
      <div className="collapse-title text-xl font-extrabold">{title}</div>
      <div className="collapse-content">
        <div>{description}</div>
      </div>
    </div>
  )
}

export default Accordion
