import FrameImage from 'assets/frame2.png'
import FrameTopImage from 'assets/frame3.png'
import WavyText from 'components/wavy-text'
import { motion } from 'framer-motion'

const APPETIZERS = [
  'Shrimp & Pork Shumai',
  'Shrimp Dumpling',
  'Fried Shrimp Ball',
  'Shrimp & Leek Dumpling',
  'Spring Roll',
  'Roast Pork Pastry',
]
const MAINS = [
  'Cold Dish Platter with Suckling Pig',
  'Lobster with Fruit Salad',
  'Sea Clam & Scallop in X.O. Sauce with Crispy Fried Milk',
  'Seafood with Fish Maw Soup',
  'Braised Whole Abalone with Shiitake Mushroom',
  'Steamed King Crab',
  'Peking Duck',
  'Steamed Whole Live Fish',
  'Walnut Shrimp',
  'Peking Porkchop',
  'Crispy Fried Chicken with Garlic Sauce',
  'Egg & Bacon Fried Rice',
  'Fried Noodle with Mushroom',
]
const DESSERTS = ['Cake', 'Donuts', 'Fruit Platter']

const Section = ({ title, items }: { title: string; items: string[] }) => {
  return (
    <div className="flex justify-center text-center flex-col w-72 md:w-[28rem] mb-6 mx-auto">
      <span className="text-3xl md:text-5xl font-bold md:font-extrabold tracking-wider mb-4 font-alex_brush">
        <div className="flex justify-center">
          <WavyText text={title} duration={0.1} />
        </div>
      </span>
      {items.map((item) => (
        <span key={item} className="text-lg md:text-2xl font-extrabold tracking-wider mb-2">
          {item}
        </span>
      ))}
    </div>
  )
}

const Menu = () => {
  return (
    <div className="md:container md:mx-auto mt-8 mb-8">
      <div className="flex justify-center flex-col">
        <div className="flex justify-center -mb-20 md:-mb-32">
          <div className="block object-cover object-center w-1/4 h-1/4 md:w-1/6 md:h-1/6 rounded-lg rotate-180">
            <motion.img animate={{ opacity: 1, y: 0, scale: 1, x: [100, 0] }} alt="frame-image" src={FrameImage} />
          </div>
          <div className="block object-cover object-center w-1/4 h-1/4 md:w-1/6 md:h-1/6 rounded-lg ml-48 rotate-180 -scale-x-100">
            <motion.img animate={{ opacity: 1, y: 0, scale: 1, x: [100, 0] }} alt="frame-image" src={FrameImage} />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="mb-6 w-2/3 h-2/3 md:w-1/6 md:h-1/6">
            <div className="block object-cover object-center rounded-lg">
              <motion.img animate={{ opacity: 1, scale: 1, y: [-100, 0] }} alt="frame-top" src={FrameTopImage} />
            </div>
          </div>
          <div className="text-4xl md:text-7xl font-bold md:font-extrabold mb-8 text-center tracking-wider font-alex_brush">
            <div className="flex justify-center">
              <WavyText text="Menu" duration={0.1} />
            </div>
          </div>
        </div>
        <Section title="Appetizers" items={APPETIZERS} />
        <Section title="Mains" items={MAINS} />
        <Section title="Dessert" items={DESSERTS} />
        <div className="flex justify-center -mt-20 md:-mt-32">
          <div className="block object-cover object-center w-1/4 h-1/4 md:w-1/6 md:h-1/6 rounded-lg mr-48 rotate-90">
            <motion.img alt="frame-image" animate={{ opacity: 1, scale: 1, y: [100, 0] }} src={FrameImage} />
          </div>
          <div className="block object-cover object-center w-1/4 h-1/4 md:w-1/6 md:h-1/6 rounded-lg">
            <motion.img animate={{ opacity: 1, y: 0, scale: 1, x: [100, 0] }} alt="frame-image" src={FrameImage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
