import Cat1Gif from 'assets/cat3.gif'
import Cat2Gif from 'assets/cat4.gif'
import TransitionCard from 'components/transition-card'
import { Variants } from 'framer-motion'
import useMediaQuery from 'hooks/use-media-query'

const LEFT_CARD_VARIANTS: Variants = {
  offscreen: {
    opacity: 0,
    x: -200,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
}

const RIGHT_CARD_VARIANTS: Variants = {
  offscreen: {
    opacity: 0,
    x: 200,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
}

const Ending = () => {
  const matches = useMediaQuery('(min-width: 768px)')
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="pt-12 md:pt-96">
        <div className="flex justify-center items-center gap-12">
          <TransitionCard cardVariants={matches ? LEFT_CARD_VARIANTS : undefined}>
            <img src={Cat1Gif} alt="cat 1 gif" className="w-20 h-20 md:w-48 md:h-48" />
          </TransitionCard>
          <TransitionCard cardVariants={matches ? RIGHT_CARD_VARIANTS : undefined}>
            <img src={Cat2Gif} alt="cat 2 gif" className="w-20 h-20 md:w-48 md:h-48" />
          </TransitionCard>
        </div>
      </div>
    </div>
  )
}

export default Ending
