import useCountdown from 'hooks/use-count-down'

interface getCountdownProps {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const getCountdown = (differences: getCountdownProps) => {
  return {
    days: { '--value': differences.days },
    hours: { '--value': differences.hours },
    min: { '--value': differences.minutes },
    sec: { '--value': differences.seconds },
  } as { [key: string]: React.CSSProperties }
}

const WEDDING_DATE = 1661643000000 // unix

const Countdown = () => {
  const [days, hours, minutes, seconds] = useCountdown(WEDDING_DATE)

  const isValid = days + hours + minutes + seconds >= 0
  if (!isValid) return null
  return (
    <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl">
          <span style={getCountdown({ days, hours, minutes, seconds }).days}></span>
        </span>
        days
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl">
          <span style={getCountdown({ days, hours, minutes, seconds }).hours}></span>
        </span>
        hours
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl">
          <span style={getCountdown({ days, hours, minutes, seconds }).min}></span>
        </span>
        minutes
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-5xl">
          <span style={getCountdown({ days, hours, minutes, seconds }).sec}></span>
        </span>
        sec
      </div>
    </div>
  )
}

export default Countdown
