import VenmoImage from 'assets/venmo.jpeg'
import ZelleImage from 'assets/zelle.jpeg'
import Accordion from 'components/accordion'
import Suggestions from './components/suggestions'

const Faqs = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col gap-3 w-full md:w-1/2 pt-8 px-3 md:px-0 md:pt-32">
        <Accordion title="What should I wear?" description="Semi formal, a nice dress or a suit." />
        <Accordion
          title="Do you have a registry?"
          description={
            <div>
              <p>
                Your presence at our reception is the best gift of all! And we are fortunate enough to have everything
                we need for our home.
              </p>
              <p className="mt-3">
                If you are thinking of giving us a gift, contributions towards our future together will be greatly
                appreciated.
              </p>
              <p className="mt-3">Our honeymoon & future home fund ❤️</p>
              <p className="mt-3">
                We will have a box for cards at the reception or you can send it electronically if you prefer.
              </p>
              <div className="my-12 flex flex-col justify-center items-center md:flex-row gap-6">
                <div className="flex flex-col justify-center items-center w-72 md:w-full">
                  <span>Handle: @Kristyn-S</span>
                  <img src={VenmoImage} alt="Venmo" className="w-48 h-48 mt-3" />
                </div>
                <div className="flex flex-col items-center justify-center w-72 md:w-full">
                  <span>Handle: kristynshieh@gmail.com</span>
                  <img src={ZelleImage} alt="Zelle" className="w-48 h-48 mt-3" />
                </div>
              </div>
            </div>
          }
        />
        <Accordion
          title="Pictures?"
          description={
            <div>
              <p className="mb-3">We'll have a photographer and also a photobooth!</p>
              <p>Please share any pictures or videos you take so we can keep these memories forever!</p>
            </div>
          }
        />
        <Accordion
          title="Is there parking?"
          description={
            <div>
              <p>Yes, the venue has a parking lot located at:</p>
              <p>LAZ Parking</p>
              <p>136-20 38th ave.</p>
              <p>Flushing, NY 11354</p>
              <p className="mt-3">Rates are as follows:</p>
              <ul>
                <li>$5 for 3 hours</li>
                <li>$6 for each additional hour</li>
              </ul>
              <p>Please remember to bring your parking ticket to be stamped at the event!</p>
              <p className="mb-3">Parking lot closes at 12am.</p>
              <p>To get to the restaurant from the parking lot:</p>
              <p>Take the elevator to the Ground floor</p>
              <p>Then transfer to another elevator to get to the 2nd floor.</p>
            </div>
          }
        />
        <Accordion
          title="What time will the event end?"
          description="We'll end around 11pm but you are welcome to leave earlier (or later)!"
        />
        <Accordion
          title="What if I can't attend anymore?"
          description={
            <div>
              <p>No worries, we understand that things come up. We'll miss you!</p>
              <p>Please let us know if you can't make it anymore!</p>
            </div>
          }
        />
        {/* <Accordion title="Advice and Wishes?" description={<Suggestions />} /> */}
        <Suggestions />
      </div>
    </div>
  )
}

export default Faqs
